.GeneralContainerRight, .GeneralContainerLeft {
    grid-template-columns: none;
    grid-template-rows: 23% 77%;
    padding: 1.5rem .5rem;
    display: grid;
    height: auto;
}

@media screen and (min-width: 450px) {
    .GeneralContainerRight, .GeneralContainerLeft {
        grid-template-rows: 35% 65%;
    }
}

@media screen and (min-width: 850px) {
    .GeneralContainerRight, .GeneralContainerLeft {
        grid-template-rows: none;
        padding: 4rem 2rem;
    }
    
    .GeneralContainerRight {
        grid-template-columns: 40% 60%;
    }
    
    .GeneralContainerLeft {
        grid-template-columns: 60% 40%;
    }
}


.TextSectionRight, .TextSectionLeft {
    padding: .5rem 0rem;
    grid-column: 1/2;
    grid-row: 2/3;
}


@media screen and (min-width: 850px) {
    .TextSectionRight, .TextSectionLeft {
        grid-row: 1/2;
    }
    
    .TextSectionRight {
        grid-column: 2/3;
    }
    
    .TextSectionLeft {
        grid-column: 1/2;
    }
}

.LogoSectionRight, .LogoSectionLeft {
    justify-content: center;
    flex-direction: column;
    grid-row: none;
    display: flex;
}

.LogoSectionRight, .LogoSectionLeft {
    grid-column: 1/2;
    grid-row: 1/2;
}

@media screen and (min-width: 850px) {

    .LogoSectionRight {
        grid-column: 2/3;
    }
    
    .LogoSectionLeft {
        grid-column: 1/2;
    }
}

.ItemsText {
    margin-bottom: 1rem;
    font-size: 1.2rem;
    font-weight: 300;
}

@media screen and (min-width: 850px) {
    .ItemsText {
        margin-bottom: 1rem;
        font-size: 1.5rem;
        font-weight: 300;
    }
}

.ItemsSpan {
    font-weight: 400;
}

.LogoItem {
    margin: 1rem auto;
    height: 8rem;
    width: 8rem;
}

@media screen and (min-width: 850px) {
    .LogoItem {
        height: 10rem;
        width: 10rem;
    }
}

.TitleLogo {
    margin: 0 auto;
    text-align: center;
    font-size: 1.3rem;
}

@media screen and (min-width: 850px) {
    .TitleLogo {
        font-size: 1.5rem;
    }
}