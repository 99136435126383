.SimpleContainer {
    background-repeat: no-repeat;
    background-position: bottom;
    justify-content: center;
    background-size: cover;
    flex-direction: column;
    align-items: center;
    padding: 1rem 2rem;
    display: flex;
    height: auto;
}

@media screen and (min-width: 800px) {
    .SimpleContainer {
        height: 70vh;
    }
}

@media screen and (min-width: 1300px) {
    .SimpleContainer {
        padding: 1rem 5rem;
    }   
}

.Title {
    text-align: center;
    font-weight: 500;
    font-size: 1.8rem;
    width: 70vw;
}

@media screen and (min-width: 1300px) {
    .Title {
        font-size: 2.8rem;
    }   
}

@media screen and (min-width: 1700px) {
    .Title {
        font-size: 3rem;
    }   
}

.Text {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 300;
    width: 60vw;
}

@media screen and (min-width: 1300px) {
    .Text {
        width: 50vw;
    }   
}

@media screen and (min-width: 1700px) {
    .Text {
        font-size: 1.4rem;
        width: 40vw;
    }   
}


