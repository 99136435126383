.navbar-button-cta{
    width: 95vw !important;
}

#back-to-top-anchor{
    min-height: 0 !important;
}

.back-to-top-arrow{
    z-index: 999;
    display: none;
}

.back-to-top-arrow button{
    background-color: #F97F59 !important;
}

@media screen and (max-width: 650px){
    .back-to-top-arrow{
        bottom: 7em !important;
    }
}

@media screen and (min-width: 650px){
    .navbar-button-cta{
        width: 15em !important;
    }
    .crossup-logo{
        width: 10em;
    }
    .gifty-logo{
        width: 5em;
    }
    .whatspro-logo{
        width: 10em;
    }
}