.Title1 {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 500;
    width: 70vw;
}

@media screen and (min-width: 600px) {
    .Title1 {
        font-size: 1.8rem;
    }  
}

@media screen and (min-width: 1300px) {
    .Title1 {
        font-size: 2.8rem;
    }   
}

@media screen and (min-width: 1700px) {
    .Title1 {
        font-size: 3rem;
    }   
}

.SpanTitle1 {
    font-weight: 700;
}

.LogosSection {
    background-color: #F5F5F5;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 2rem 1rem;
    display: flex;
    gap: 30px;
}

@media screen and (min-width: 400px) {
    .LogosSection {
        padding: 2.5rem 0rem;
        flex-direction: row;
    }   
}

.FlowyImg {
    height: 3.5rem;
}

@media screen and (min-width: 300px) {
    .FlowyImg {
        height: 4rem;
    }   
}

@media screen and (min-width: 500px) {
    .FlowyImg {
        height: 6rem;
    }   
}

.PerfitImg {
    margin-left: 0rem;
    height: 2.5rem;
}

@media screen and (min-width: 600px) {
    .PerfitImg {
        margin-left: 2rem;
        height: 4rem;
    }   
}

.Plus {
    margin: 0rem 0rem 1rem 0rem; 
    font-size: 2.5rem; 
    font-weight: 600; 
    color: #4897C2;
}

@media screen and (min-width: 500px) {
    .Plus {
        margin: 0; 
    }   
}

.Section3Container {
    text-align: center;
    padding: 3rem 1rem;
}

.TitleSection3 {
    color: #2C3357;
    font-size: 2rem;
}

@media screen and (min-width: 1300px) {
    .TitleSection3 {
        font-size: 2.5rem;
    }   
}

.Section3Container p {
    font-weight: 400;
    font-size: 1rem;
    width: auto;
}

.SpanText2, .SpanTitle5, .SpanP5 {
    color: #4897C2;
}

@media screen and (min-width: 500px) {
    .Section3Container p {
        margin: 0rem auto 1.5rem auto;
        width: 50vw;
    }   
}

@media screen and (min-width: 1300px) {
    .Section3Container p {
        font-size: 1.3rem;
    }   
}

.Section4Container {
    background-image: url('../../assets/Background/Background2.png');
    background-position: top;
    background-repeat: no-repeat;
    justify-content: center;
    background-size: cover;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 2rem 1rem;
    display: flex;
    height: auto;
}

@media screen and (min-width: 1200px) {
    .Section4Container {
        padding: 5rem 1rem;
        height: 50vh;
    }   
}

.Section4Container h1 {
    font-size: 2rem;
    color: white;
}

.Section4Container p {
    list-style-position: inside;
    list-style-type: disc;
    display: list-item;
    font-size: 1.2rem;
    font-weight: 300;
    color: white;
    margin: 0 auto;
}

.Section5Container {
    text-align: center;
    padding: 3rem;
}

@media screen and (min-width: 1200px) {
    .Section5Container {
        padding: 6rem 1rem;
    }   
}

.Section5Container h1 {
    font-size: 1.5rem;
    margin: auto;
    width: auto;
}

@media screen and (min-width: 600px) {
    .Section5Container h1 {
        font-size: 2rem;
        width: 70vw;
    }   
}

@media screen and (min-width: 1200px) {
    .Section5Container h1 {
        width: 50vw;
    }   
}

.Section5Container p {
    margin: 2rem auto 0rem auto;
    font-size: 1.2rem;
    font-weight: 400;
    width: auto;
}

@media screen and (min-width: 600px) {
    .Section5Container p {
        width: 70vw;
    }   
}

@media screen and (min-width: 1200px) {
    .Section5Container p {
        width: 40vw;
    }   
}

.Section6Container {
    background-color: #2C3357;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: white;
    display: flex;
    padding: 2rem;
    height: auto;
}

@media screen and (min-width: 600px) {
    .Section6Container {
        height: 40vh;
    }
}

.Section6Container h1 {
    font-size: 1.5rem;
    font-weight: 500;
    width: auto;
}

@media screen and (min-width: 600px) {
    .Section6Container h1 {
        font-size: 2rem;
        width: 70vw;
    }   
}

@media screen and (min-width: 1200px) {
    .Section6Container h1 {
        width: 60vw;
    }   
}

.Section6Container p {
    font-size: 1.4rem;
    font-weight: 300;
}

.TextNumber {
    padding-left: .5rem;
    font-size: 1rem;
    text-align: left;
    color: #2C3357;
    margin: auto;
}

@media screen and (min-width: 850px) {
    .TextNumber {
        font-size: 1.3rem;
    }
}

.Section7Container {
    grid-template-columns: none;
    grid-template-rows: 35% 65%;
    background-color: white;
    text-align: center;
    padding: 2rem 1rem;
    min-height: 600px;
    display: grid;
}

.Section8Container {
    grid-template-columns: none;
    grid-template-rows: 70% 30%;
    background-color: #2C3357;
    text-align: center;
    padding: 2rem 1rem;
    min-height: 600px;
    display: grid;
}

@media screen and (min-width: 600px) {
    .Section7Container, .Section8Container {
        grid-template-columns: 50% 50%;
        grid-template-rows: none;
    }
}

.TextLeftContainer, .TextRightContainer {
    text-align: center;
    grid-column: 1/2;
    grid-row: 1/2;
    display: flex;
    margin: auto;
    width: 70%;
}

@media screen and (min-width: 600px) {
    .TextLeftContainer {
        grid-column: 1/2;
    } 
    
    .TextRightContainer {
        grid-column: 2/3;
    }
}

.TextLeftContainer h1, .TextRightContainer h1 {
    font-size: 4rem;
    color: #4897C2;
    margin: -1.5rem .3rem 0 0;
}

@media screen and (min-width: 1300px) {
    .TextLeftContainer h1, .TextRightContainer h1 {
        font-size: 5rem;
    }
}

.ImageLeftContainer, .ImageRightContainer {
    justify-content: center;
    align-items: center;
    grid-column: 1/2;
    grid-row: 2/3;
    display: flex;
}

@media screen and (min-width: 600px) {
    .ImageLeftContainer, .ImageRightContainer {
        grid-row: 1/2;
    }

    .ImageLeftContainer {
        grid-column: 2/3;
    } 
    
    .ImageRightContainer {
        grid-column: 1/2;
    }
}

.ImgSection7, .ImageRightContainer img {
    width: 70%;
    height: auto;
}


@media screen and (min-width: 1300px) {
    .ImgSection7 {
        width: 50%;
    }
}

.Section9Container {
    padding: 1rem 1rem 2rem 1rem;
    background-color: white;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    display: flex;
    height: auto;
}

@media screen and (min-width: 1600px) {
    .Section9Container {
        height: 45vh;
    }
}

.Section9Container h1 {
    margin-bottom: .6rem;
    font-size: 1.5rem;
    color: #2C3357;
    font-weight: 600;
    width: auto;
}

@media screen and (min-width: 600px) {
    .Section9Container h1 {
        font-size: 2rem;
        width: 70vw;
    }   
}

@media screen and (min-width: 1200px) {
    .Section9Container h1 {
        width: 40vw;
    }   
}

.Section9Container p {
    font-size: 1.2rem;
    color: #2C3357;
    width: auto;
}

@media screen and (min-width: 600px) {
    .Section9Container p {
        width: 50vw;
    }   
}

@media screen and (min-width: 1200px) {
    .Section9Container p {
        width: 30vw;
    }   
}

