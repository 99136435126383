.NumberLeftContainer, .NumberRightContainer {
    grid-template-columns: none;
    grid-template-rows: 50% 50%;
    text-align: center;
    padding: 2rem 1rem;
    min-height: 500px;
    display: grid;
}

@media screen and (min-width: 600px) {
    .NumberLeftContainer, .NumberRightContainer {
        grid-template-columns: 50% 50%;
        grid-template-rows: none;
    }
}

.TextLeftContainer, .TextRightContainer {
    text-align: center;
    grid-column: 1/2;
    grid-row: 1/2;
    display: flex;
    margin: auto;
    width: 70%;
}

@media screen and (min-width: 600px) {
    .TextLeftContainer {
        grid-column: 1/2;
    } 
    
    .TextRightContainer {
        grid-column: 2/3;
    }
}

.TextLeftContainer h1, .TextRightContainer h1 {
    font-size: 4rem;
    color: #4897C2;
    margin: -1.5rem .3rem 0 0;
}

@media screen and (min-width: 600px) {
    .TextLeftContainer h1, .TextRightContainer h1 {
        font-size: 5rem;
        margin: -2rem .3rem 0 0;
    }
}

.ImageLeftContainer, .ImageRightContainer {
    justify-content: center;
    align-items: center;
    grid-column: 1/2;
    grid-row: 2/3;
    display: flex;
}

@media screen and (min-width: 600px) {
    .ImageLeftContainer, .ImageRightContainer {
        grid-row: 1/2;
    }

    .ImageLeftContainer {
        grid-column: 2/3;
    } 
    
    .ImageRightContainer {
        grid-column: 1/2;
    }
}

.ImageLeftContainer img, .ImageRightContainer img {
    width: 70%;
    height: auto;
} 