*{
    font-family: 'Poppins', sans-serif !important;
}

body{
    /* background-color: #F5F5F5 !important; */
    margin: 0 !important;
}
*{
    -ms-overflow-style: none;
}
::-webkit-scrollbar {
    display: none;
}
.progress-top-bar{
    z-index: 999999;
}

#country-menu{
    z-index: 9999;
}