.progress-top{
    top: 0 !important;
}

.logo-woki{
    position: absolute;
    top: 1em;
    right: 1em;
    width: 20vw;
    z-index: 1;
}

@media screen and (min-width: 800px){
    .hero-container{
        padding-right: 0 !important;   
    }
    .gradient-overlay3{
        position: relative;
    }
    .gradient-overlay3::after{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 1%;
        height: 99%;
        background-image: linear-gradient(90deg, #f5f5f5 0%, #f4f4f4 100%) !important;
        z-index: 3;
        opacity: 1;
    }
}
@media screen and (max-width: 800px){
    .MuiContainer-root{
        /* max-width: 1600px !important; */
        padding-right: 0px !important;
    }
    .hero-title{
        position: relative;
        top: -2em;
        border-radius: 1em 1em 0em 0em;
        z-index: 10;
        background-color: white;
        padding-left: 0em !important;
    }
}

@media screen and (min-width: 800px){
    .MuiContainer-root{
        width: 100vw !important;
        max-width: 100vw !important;
    }
    .hero-button-cta{
        width: 15em !important;
    }

}